<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500 ml50 mt20">加盟列表</div>
		<div class="mt20 pd20 bg-w">
			<!-- <div>
				<a-button type="primary" icon="export" @click="exportMemberAct()">导出会员</a-button>
			</div> -->
			<div class="wxb-table-gray mt20">
				<a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					
					<!-- <div class="flex alcenter center" slot="integral" slot-scope="integral,record">
						{{integral==null ? '0' : integral.remain_integral}}
					</div> -->
			
					<template slot="action" slot-scope="record,index" v-if="record.status==0">
                        <a-button type="primary" @click="agreeCooperate(record)">
                            同意
                        </a-button>
                        <a-button type="danger" style="margin-left: 10px;" @click="refuseCooperate(record)">
                            拒绝
                        </a-button>
					</template>
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import { listMixin } from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		
		data() {
			return {
				loading: false,
				visible:false,
				editloading:false,
				confirmLoading:false,
				changePidVisibel:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					nick_name: '',
					mobile: "",
					level_id: -99,
					birthday_type:1,
					carNum:''
				},
				// level:[],
				columns: [
					{title: 'id',dataIndex: 'id',align: 'center'},
					{title: '地址',dataIndex: 'address',align: 'center',scopedSlots: {customRender: 'address'}},
					{title: '姓名',dataIndex: 'name',align: 'center',scopedSlots: {customRender: 'name'}},
					{title: '联系方式',dataIndex: 'phone',align: 'center',scopedSlots: {customRender: 'phone'}},
					{title: '提交时间',dataIndex: 'add_time',align: 'center',scopedSlots: {customRender: 'add_time'},customRender: (text) => { return new Date(text * 1000).toLocaleString() }},
					{title: '状态',dataIndex: 'status',align: 'center',scopedSlots: {customRender: 'status'},customRender: (text) => { 
                        if(text==0){
                            return '待审核'
                        }else if(text==1){
                            return '同意'
                        }else if(text==2){
                            return '拒绝'
                        }
                     }},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
				member:{},
				member_id:0,
			}
		},
		methods: {
            agreeCooperate(item){
                console.log(item);
                this.$http.api('admin/process_join',{
					status: 1,
                    id: item.id
				}).then(res=>{
				    this.getLists();
				}).catch(res=>{
					this.loading = false;
				})
            },
            refuseCooperate(item){
                console.log(item);
                this.$http.api('admin/process_join',{
					status: 2,
                    id: item.id
				}).then(res=>{
				    this.getLists();
				}).catch(res=>{
					this.loading = false;
				})
            },
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/joinlist',{
					limit: this.pagination.pageSize,
					page: this.pagination.current,
				}).then(res=>{
					this.pagination.total = res.total;
					this.datas = res.list;
					// this.level=res.level;
					this.loading = false;
				}).catch(res=>{
					this.loading = false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.member-index-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}

	.member-index-action {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
</style>
